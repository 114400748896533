import { AuthenticationService } from '@services/authentication/authentication.service';
import { CanLoad, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ToastService } from '@services/toast/toast-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(
    private router: Router,
    private _authService: AuthenticationService,
    private toast: ToastService,
    private translate: TranslateService
  ) {}

  canLoad(): Observable<boolean> {
    return this._authService.isAuthenticated.pipe(
      filter((val) => val !== null),
      take(1),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.toast.presentToast(this.translate.instant('sidemenu.must_login'), 'info');
        }
        return isAuthenticated;
      })
    );
  }
}
