import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router';

import { GoogleInputComponent } from '@components/google-input/google-input.component';
import { TranslateService } from '@ngx-translate/core';
import { MyWaiisService } from '@services/modals/my-waiis/my-waiis.service';
import { RouteService } from '@services/route/route.service'
import { RoutinesService } from '@services/routines/routines.service';
import { ToastService } from '@services/toast/toast-service';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import { addressTypes } from '@app/constants';
import { GoogleMapService } from '@services/google-map/google-map.service';
import { UserService } from '@services/user/user.service';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuscadorComponent implements OnInit {
  open: boolean = false
  text: string = "A dónde vas?"
  origin;
  destination;

  addFavorite: boolean = false
  addressName: string
  favoriteAddressType: string = 'other'
  favoriteTypeModal: boolean = false
  favoriteAddressCoords: { lat: number, lng: number }

  addressTypes = addressTypes
  editingFavorite: boolean = false
  deleteFavoriteModal: boolean = false

  @Input() creatingRoute: boolean = false

  constructor(
    private routes: RouteService,
    private vehicles: VehiclesService,
    private toast: ToastService,
    private translate: TranslateService,
    private ref: ChangeDetectorRef,
    private myWaiis: MyWaiisService,
    private router: Router,
    private routine: RoutinesService,
    private googleMapService: GoogleMapService,
    private userService: UserService,
    private analytics: AnalyticsService
  ) { }

  @ViewChild('inputOrigin') origenInput: GoogleInputComponent;
  @ViewChild('inputDestination') destinoInput: GoogleInputComponent;
  @ViewChild('favoriteInput') favoriteInput: GoogleInputComponent;

  ngOnInit() {
    if (this.creatingRoute) {
      this.open = true
    }

    if (this.creatingRoute) {
      this.routine.origen.subscribe(origin => {
        this.origin = origin
        this.ref.detectChanges()
      })
      this.routine.destino.subscribe(destination => {
        this.destination = destination
        this.ref.detectChanges()
      })
    } else {
      this.routes.origen.subscribe(origen => {
        this.origin = origen
        this.ref.detectChanges()
      })

      this.routes.destino.subscribe(destino => {
        this.text = destino?.nombre || 'Escribe tu destino'

        this.destination = destino
        this.ref.detectChanges()
      })
    }
  }

  onFocused(type) {
    if (type === 'origen') {
      this.destinoInput.hideOptions()
    } else if (type === 'destino') {
      this.origenInput.hideOptions()
    }
  }

  setLocation(type, address) {
    this.destinoInput?.hideOptions()
    this.origenInput?.hideOptions()

    if (this.creatingRoute) {
      this.routine.setUbicacion(type, address)
    } else {
      this.routes.setUbicacion(type, address)
    }
  }

  openBuscador() {
    this.myWaiis.dismiss()
    this.open = true
    this.destinoInput.focus()
  }

  back() {
    if (this.creatingRoute) {
      this.router.navigate([''], { replaceUrl: true })
    } else {
      if (this.routes.selectedActivo.value === null && this.vehicles.selectedActivo.value === null) {
        this.myWaiis.openModal()
      }
      this.open = false
    }
  }

  async switchRoute() {
    await this.routes.switchRoute()
  }

  continue() {
    if (this.origin && this.destination) {
      if (this.creatingRoute) {
        this.analytics.logEvent('crear_rutina', {})
        this.router.navigate(['route-config'])
      } else {
        this.vehicles.getAssets()
        this.open = false
      }
    } else {
      this.toast.presentToast(this.translate.instant('route_config.info_required_origin_destination'), 'info');
    }
  }

  openFavoriteTypeModal() {
    this.favoriteTypeModal = true
  }

  onModalDismiss() {
    this.favoriteTypeModal = false
  }

  manageFavorites(preset?) {
    this.origenInput.blur()
    this.destinoInput.blur()

    if (preset) {
      this.addressName = preset.name
      this.favoriteAddressType = preset.type === 'add' ? 'other' : preset.type

      if (preset.nombre) {
        this.editingFavorite = preset.id
        this.text = preset.nombre
        this.favoriteInput.setValue(preset.nombre)
      }
      if (preset.lat && preset.lng) {
        const coords = {
          lat: parseFloat(preset.lat),
          lng: parseFloat(preset.lng),
        }
        this.googleMapService.setMarker("favorite", coords)
        this.favoriteAddressCoords = coords
      }
    }
    this.addFavorite = true
  }

  setFavoriteType(type) {
    this.favoriteAddressType = type
    this.favoriteTypeModal = false
  }

  setFavoriteAddress(_, address) {
    this.destinoInput.hideOptions()
    this.origenInput.hideOptions()
    this.favoriteInput.hideOptions()

    this.text = address.nombre
    this.googleMapService.setMarker("favorite", address.coords)
    this.favoriteAddressCoords = address.coords
  }

  deleteFavorite() {
    this.userService.deleteFavoriteAddress(this.editingFavorite)
    this.deleteFavoriteModal = false
    this.addFavorite = false
  }

  saveFavorite() {
    if (this.editingFavorite) {
      this.userService.editFavoriteAddress(this.editingFavorite, this.favoriteAddressCoords, this.favoriteAddressType, this.addressName)
      this.editingFavorite = false
    } else {
      this.userService.addFavoriteAddress(this.favoriteAddressCoords, this.favoriteAddressType, this.addressName)
    }
    this.addFavorite = false
  }
}
