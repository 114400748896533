import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutinesService } from '@services/routines/routines.service';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import { displayDate } from '@app/utils';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@services/translate/translate.service';
import { ToastService } from '@services/toast/toast-service';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss'],
})
export class RoutesComponent implements OnInit {
  routes: any[] = []
  routines: any[] = []

  routinesSubscription: Subscription
  routesSubscription: Subscription

  aWeekFromNow = dayjs().add(6, 'day')

  routeToDelete: string

  routineToDelete: string
  routineDayToDelete: string

  modalDeleteRoute: CupertinoPane
  modalDeleteRouteSettings: CupertinoSettings = {
    bottomClose: false,
    fitHeight: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    parentElement: 'body',
    buttonDestroy: false,
    showDraggable: false,
    draggableOver: false,
    dragBy: []
  }

  modalDeleteRoutine: CupertinoPane
  modalDeleteRoutineSettings: CupertinoSettings = {
    bottomClose: false,
    fitHeight: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    parentElement: 'body',
    buttonDestroy: false,
    showDraggable: false,
    draggableOver: false,
    dragBy: []
  }

  constructor(
    private router: Router,
    private routinesService: RoutinesService,
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslateService,
    private toast: ToastService,
  ) {  }

  addRoute() {
    this.router.navigate(['route-search'])
  }

  ngOnInit() {
    this.modalDeleteRoute = new CupertinoPane(
      '.modalDeleteRoute',
      this.modalDeleteRouteSettings
    )

    this.modalDeleteRoutine = new CupertinoPane(
      '.modalDeleteRoutine',
      this.modalDeleteRoutineSettings
    )

    this.routinesSubscription = this.routinesService.routines.subscribe(routines => {
      this.routines = routines
    })
    this.routesSubscription = this.routinesService.routes.subscribe(routes => {
      this.routes = routes.filter(route => dayjs(route.start_date+route.departure_time).isAfter(dayjs())).filter(route => dayjs(route.start_date).isSameOrBefore(this.aWeekFromNow)).sort((a, b) => dayjs(a.start_date).diff(dayjs(b.start_date)))
    })
  }

  getWeekDay(date: string) {
    return displayDate(date, { onlyDayShort: true })
  }

  getRoutineName(route: any) {
    const routine = this.routines.find(routine => routine.id === route.pooling_routine_id)
    return routine.name
  }

  ngOnDestroy() {
    this.routinesSubscription.unsubscribe()
    this.routesSubscription.unsubscribe()
  }

  async presentActionSheet(route) {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: this.translate.instant('common.delete'),
          role: 'destructive',
          handler: () => {
            const departure = dayjs(`${route.start_date}T${route.departure_time}Z`)
            const difference = departure.diff(dayjs(), 'minute')
            if (difference < 1440) {
              this.toast.presentToast(this.translate.instant('my_waiis.routes.delete_before_24h'), 'error')
            } else {
              this.routeToDelete = route.id
              this.modalDeleteRoute.present()
            }
          },
        },
        // {
        //   text: this.translate.instant('my_waiis.routes.delete_routine'),
        //   role: 'destructive',
        //   handler: () => {
        //     const departure = dayjs(`${route.start_date}T${route.departure_time}Z`)
        //     const difference = departure.diff(dayjs(), 'minute')
        //     if (difference < 1440) {
        //       this.toast.presentToast(this.translate.instant('my_waiis.routes.delete_before_24h'), 'error')
        //     } else {
        //       this.routineToDelete = route.pooling_routine_id
        //       this.routineDayToDelete = route.start_date
        //       this.modalDeleteRoutine.present()
        //     }
        //   },
        // },
        {
          text: this.translate.instant('common.cancel'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();
  }

  displayDepartureTime(route) {
    return displayDate(`${route.start_date}T${route.departure_time}Z`, { onlyHour: true })
  }

  confirmDeleteRoute() {
    this.routinesService.deleteRoute(this.routeToDelete)
    this.modalDeleteRoute.hide()
  }

  confirmDeleteRoutine() {
    this.routinesService.deleteRoutine(this.routineToDelete, this.routineDayToDelete)
    this.modalDeleteRoutine.hide()
  }
}
