import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() state = "default"
  @Input() type = "primary"
  @Input() data_cy = ""

  constructor() { }

  get ngClasses() {
    if (this.type === 'primary') {
      if (this.state === 'default') {
        return 'bg-primary text-text'
      } else {
        return 'bg-primary-shade text-text'
      }
    } else {
      if (this.state === 'default') {
        return 'bg-transparent border border-primary border-solid text-primary'
      } else {
        return 'bg-transparent border border-primary border-solid text-primary opacity-30'
      }
    }
  }

  ngOnInit() { }
}
