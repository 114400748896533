import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { formatPrice } from '@app/utils';
import { AnimationController } from '@ionic/angular';
import { MyWaiisService } from '@services/modals/my-waiis/my-waiis.service';
import { RoutinesService } from '@services/routines/routines.service';
import { UserService } from '@services/user/user.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-my-waiis',
  templateUrl: './my-waiis.component.html',
  styleUrls: ['./my-waiis.component.scss'],
})
export class MyWaiisComponent implements OnInit {
  currentBreakpoint: number
  @ViewChild('handleIcon', { read: ElementRef }) handleIcon: ElementRef;
  userName
  profileImage

  tab: 'data' | 'calendar' | 'routes' = 'routes'

  balance: number;
  couponsBalance: number;

  constructor(
    private userService: UserService,
    private router: Router,
    private animationCtrl: AnimationController,
    private myWaiis: MyWaiisService,
    private routinesService: RoutinesService
  ) {
    this.getCalendarEvents()

    this.userService.user.subscribe(user => {
      this.userName = user?.name
      this.profileImage = user?.selfie_url
    })

    this.userService.balance.subscribe(balance => {
      this.balance = Math.abs(balance)
    })

    this.userService.couponsBalance.subscribe(couponsBalance => {
      this.couponsBalance = Math.abs(couponsBalance)
    })

    this.myWaiis.currentBreakpoint.subscribe(currentBreakpoint => {
      if (this.currentBreakpoint === this.myWaiis.breakpoints[1] && currentBreakpoint !== this.myWaiis.breakpoints[1]) {
        this.handleDirection("down")
      } else if (this.currentBreakpoint !== this.myWaiis.breakpoints[1] && currentBreakpoint === this.myWaiis.breakpoints[1]) {
        this.handleDirection("up")
      }
      this.currentBreakpoint = currentBreakpoint
    })
  }

  toggleTab(tab: 'data' | 'calendar' | 'routes') {
    this.tab = tab
  }

  handleDirection(direction: "up" | "down") {
    const animationStart = direction === "up" ? 'rotate(0deg)' : 'rotate(180deg)';
    const animationEnd = direction === "up" ? 'rotate(180deg)' : 'rotate(360deg)';

    const modalHandlerAnimation = this.animationCtrl.create('modal-handler-animation')
      .addElement(this.handleIcon.nativeElement)
      .duration(100)
      .fromTo('transform', animationStart, animationEnd);

    modalHandlerAnimation.play();
  }

  changeBreakpoint() {
    this.myWaiis.changeBreakpoint()
  }

  navigateToProfile() {
    this.router.navigate(['profile'])
  }

  formatPrice(price: number) {
    return formatPrice(price)
  }

  getCalendarEvents(selectedDate?: Date) {
    const date = dayjs(selectedDate)

    const start_date = date.subtract(2, 'month').startOf('month').format('YYYY-MM-DD')
    const end_date = date.add(2, 'month').endOf('month').format('YYYY-MM-DD')

    this.userService.getCalendarEvents(start_date, end_date)
  }

  ngOnInit() {
    this.routinesService.getRoutines()
    this.routinesService.getRoutes()
  }
}