import { Injectable } from '@angular/core';
import { Type } from '@app/models/Activo';
import { ModalController } from '@ionic/angular';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import { BehaviorSubject } from 'rxjs';
import { ComparadorService } from '../comparador/comparador.service';
import { ResumenRentComponent } from '@components/resumen-rent/resumen-rent.component';

@Injectable({
  providedIn: 'root'
})
export class ResumenRentService {
  modal
  open = new BehaviorSubject<boolean>(false)
  constructor(
    private modalCtrl: ModalController,
    private vehicles: VehiclesService,
    private comparador: ComparadorService
  ) { }

  types = new BehaviorSubject<{[key in Type]: boolean}>({
    rider: true,
    driver: true,
    public: true
  })

  toggleType = (type: Type) => {
    this.types.next({
      ...this.types.getValue(),
      [type]: !this.types.getValue()[type]
    })
  }

  async openModal() {
    this.comparador.dismiss()
    this.modal = await this.modalCtrl.create({
      component: ResumenRentComponent,
      breakpoints: [0, 0.27, 0.43, 0.6],
      initialBreakpoint: 0.6,
      backdropDismiss: true,
    });
    this.modal.present();
    this.open.next(true)

    await this.modal.onWillDismiss();
    this.open.next(false)
    this.vehicles.selectActivo(null)
    this.comparador.openModal()
  }

  dismiss() {
    this.modal.dismiss()
  }
}
