import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';

import { GeolocationService } from '@services/geolocation/geolocation.service';
import { GoogleMapService } from '@services/google-map/google-map.service';
import { Subscription, first } from 'rxjs';
import { TranslateService } from '@services/translate/translate.service';
import { MapGeocoder } from '@angular/google-maps';
import { UserService } from '@services/user/user.service';
import { Keyboard } from '@capacitor/keyboard';
import { Platform } from '@ionic/angular';
import { RouteService } from '@services/route/route.service';
import { SafeArea } from 'capacitor-plugin-safe-area';

const terminalT1 = {
  "address_components": [
    {
      "long_name": "Terminal T1",
      "short_name": "Terminal T1",
      "types": [
        "premise"
      ]
    },
    {
      "long_name": "Aeropuerto de Barcelona",
      "short_name": "Aeropuerto de Barcelona",
      "types": [
        "route"
      ]
    },
    {
      "long_name": "El Prat de Llobregat",
      "short_name": "El Prat de Llobregat",
      "types": [
        "locality",
        "political"
      ]
    },
    {
      "long_name": "Barcelona",
      "short_name": "B",
      "types": [
        "administrative_area_level_2",
        "political"
      ]
    },
    {
      "long_name": "Catalunya",
      "short_name": "CT",
      "types": [
        "administrative_area_level_1",
        "political"
      ]
    },
    {
      "long_name": "España",
      "short_name": "ES",
      "types": [
        "country",
        "political"
      ]
    },
    {
      "long_name": "08820",
      "short_name": "08820",
      "types": [
        "postal_code"
      ]
    }
  ],
  "formatted_address": "Terminal T1, Aeropuerto de Barcelona, 08820 El Prat de Llobregat, Barcelona, España",
  "geometry": {
    "location": {
      "lat": 41.289182,
      "lng": 2.0746423
    },
    "viewport": {
      "south": 41.28649295,
      "west": 2.073011669708498,
      "north": 41.29007835000001,
      "east": 2.075709630291502
    }
  },
  "name": "Terminal T1",
  "place_id": "ChIJw2hK2XGepBIReN-7Fx_hNUE",
  "types": [
    "premise"
  ],
  "html_attributions": [],
  "secondary_text": "Aeropuerto de Barcelona, El Prat de Llobregat, España"
}

const presets = [
  {
    name: "favorites.other_address",
    type: "add",
  },
  {
    name: "favorites.work_address",
    type: "work",
  },
  {
    name: "favorites.home_address",
    type: "home",
  }
]

@Component({
  selector: 'app-google-input',
  templateUrl: './google-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleInputComponent implements OnInit {
  @Input() placeholder: string;
  @Input() type: 'origen' | 'destino' | 'favorite';
  @Input() backButton: boolean = false

  @Output() setLocation = new EventEmitter()
  @Output() backButtonAction = new EventEmitter()
  @Output() manageFavorites = new EventEmitter()
  @Output() onFocused = new EventEmitter()

  private timeout: any;
  private isPressing: boolean = false;
  private readonly pressDuration: number = 1000;

  @ViewChild('googleInput', { read: ElementRef, static: true })
  googleInput: ElementRef;

  @ViewChild('results') resultsElement: ElementRef
  @ViewChild('favorites') favoritesElement: ElementRef

  resultados: {
    place_id: string
    secondary_text?: string
    non_matched_prefix?: string
    matched: string
    non_matched_suffix?: string
    types?: string[]
  }[] = [{
    place_id: "located",
    matched: this.translateService.instant("search.your_location"),
  }];

  geolocatedSubscription: Subscription

  autocomplete = new google.maps.places.AutocompleteService();
  places

  nombre: string
  located: boolean = false

  geolocatedLocation;

  screenHeight: number;

  favoriteAddresses: any[]

  presets: any[] = []

  constructor(
    private geolocationService: GeolocationService,
    private ref: ChangeDetectorRef,
    private googleMapService: GoogleMapService,
    private translateService: TranslateService,
    private geocoder: MapGeocoder,
    private userService: UserService,
    private platform: Platform,
    private routeService: RouteService,
    private ngZone: NgZone
  ) {
    this.screenHeight = this.platform.height()

    this.userService.favoriteAddresses.subscribe(favoriteAddresses => {
      this.favoriteAddresses = favoriteAddresses

      this.presets = []

      presets.forEach(preset => {
        if (!favoriteAddresses.find(item => item.type === preset.type) && !this.presets.find(item => item.type === preset.type)) {
          this.presets.unshift({
            name: this.translateService.instant(preset.name),
            type: preset.type,
          })
        }
      })
    })
  }

  ngOnInit() {
    if (this.type === "origen" || this.type === "destino") {
      this.routeService.shouldUpdate[this.type].subscribe(shouldUpdate => {
        if (shouldUpdate) {
          this.forceUpdate(this.routeService[this.type].getValue())
          this.routeService.shouldUpdate[this.type].next(false)
        }
      })
  
      if (this.type === 'origen') {
        this.geolocationService.locatedOrigin.subscribe(locatedOrigin => {
          this.located = locatedOrigin
        })
      }
    }

    this.places = new google.maps.places.PlacesService(this.googleMapService.mapa);

    this.setValue("")

    this.geolocatedSubscription = this.geolocationService.location.subscribe(location => {
      if (location) {
        if (this.located && !this.googleInput.nativeElement.value || this.googleInput.nativeElement.value === this.translateService.instant("search.your_location")) {
          this.selectManual(location)
        }
        this.geolocatedLocation = location
        this.resultados[0].secondary_text = location.nombre_largo
      }
    })

    this.googleInput.nativeElement.addEventListener('input', () => this.inputEventHandler())

    SafeArea.getSafeAreaInsets().then((response) => {
      this.favoritesElement.nativeElement.style.maxHeight = this.screenHeight - (240 + response.insets.bottom) + 'px'
      this.resultsElement.nativeElement.style.maxHeight = this.screenHeight - (240 + response.insets.bottom) + 'px'
      if (!this.platform.platforms().includes("mobileweb") && !this.platform.platforms().includes('desktop')) {
        Keyboard.addListener('keyboardWillShow', ({ keyboardHeight }) => {
          this.resultsElement.nativeElement.style.maxHeight = this.screenHeight - this.resultsElement.nativeElement.offsetTop - keyboardHeight + 'px'
          this.favoritesElement.nativeElement.style.maxHeight = this.screenHeight - this.favoritesElement.nativeElement.offsetTop - keyboardHeight + 'px'
        });
  
        Keyboard.addListener('keyboardWillHide', () => {
          this.resultsElement.nativeElement.style.maxHeight = this.screenHeight - (240 + response.insets.bottom) + 'px'
          this.favoritesElement.nativeElement.style.maxHeight = this.screenHeight - (240 + response.insets.bottom) + 'px'
        });
      }
    });

  }

  ngOnDestroy() {
    this.geolocatedSubscription.unsubscribe()
    this.googleInput.nativeElement.removeEventListener('input', this.inputEventHandler)
    if (!this.platform.platforms().includes("mobileweb") && !this.platform.platforms().includes('desktop')) {
      Keyboard.removeAllListeners()
    }
  }

  inputEventHandler() {
    if (this.googleInput.nativeElement.value) {
      this.resultsElement.nativeElement.style.display = 'block';
      if (this.type !== 'favorite') {
        this.favoritesElement.nativeElement.style.display = 'none';
      }
    } else {
      this.favoritesElement.nativeElement.style.display = 'block';
      this.resultsElement.nativeElement.style.display = 'none';
    }
    this.getPlacePredictions(this.googleInput.nativeElement.value);
  }

  setValue(name: string) {
    this.nombre = name
    this.googleInput.nativeElement.value = name
    // TODO: Replantear esto cuando se haga refactor de libreria de Google
    this.ref.detectChanges()
  }

  selectManual(place) {
    let value = place.nombre
    if (place.place_id === 'located') {
      value = place.nombre_largo
    }
    this.resultados[0].secondary_text = place.nombre_largo
    this.setValue(value)
    this.setLocation.emit(place)
  }

  goBack() {
    this.backButtonAction.emit()
  }

  async selectSearchResult(place) {
    let placeDetails = place
    let coords = {
      lat: placeDetails.geometry.location.lat(),
      lng: placeDetails.geometry.location.lng(),
    }

    let types = placeDetails.types

    if (placeDetails.name === "Aeropuerto Josep Tarradellas Barcelona-El Prat") {
      placeDetails = terminalT1
      coords = terminalT1.geometry.location
    }

    this.setValue(placeDetails.name)

    let locality = placeDetails.address_components.find((address_component) =>
      address_component.types.includes('locality')
    )?.long_name;

    await this.geocoder.geocode({ location: coords }).forEach(res => {
      res.results.forEach(result => {
        if (!locality) {
          locality = result.address_components.find(address => address.types.includes('locality'))?.long_name
        }

        if (!types.includes('airport') && result.types.includes('airport')) {
          types.push('airport')
          return
        }
      })
    })

    const ubicacion = {
      nombre: placeDetails.name,
      secondary_text: this.googleInput.nativeElement.value,
      nombre_largo: placeDetails.formatted_address,
      locality,
      coords,
      place_id: placeDetails.place_id,
      types: types
    };

    this.setLocation.emit(ubicacion)

    if (this.type === 'origen') {
      document.getElementById("googleInput-destino").focus()
    }
  }

  forceUpdate(newValue) {
    if (newValue) {
      this.setValue(newValue?.nombre)
    } else {
      this.setValue("")
    }
  }

  onFocus() {
    setTimeout(() => {
      if (!this.googleInput.nativeElement.value) {
        this.favoritesElement.nativeElement.style.display = 'block';
      }
    }, 0);

    this.geolocationService.locatedOrigin.next(false)

    this.onFocused.emit()
  }

  hideOptions() {
    if (this.resultsElement?.nativeElement?.style?.display === 'block') {
      this.resultsElement.nativeElement.style.display = 'none';
    }
    if (this.favoritesElement?.nativeElement?.style?.display === 'block') {
      this.favoritesElement.nativeElement.style.display = 'none';
    }
  }

  clearInput() {
    this.googleInput.nativeElement.value = ""
    this.setLocation.emit(null)
    this.focus()
  }

  focus() {
    setTimeout(() => {
      this.googleInput.nativeElement.focus();
    }, 100)
  }

  blur() {
    this.googleInput.nativeElement.blur()
  }

  getPlacePredictions(input) {
    const location = new google.maps.LatLng(
      this.geolocationService.location.getValue()?.coords.lat,
      this.geolocationService.location.getValue()?.coords.lng
    )

    const bias = new google.maps.Circle({
      center: location,
      radius: 5000
    })

    this.autocomplete.getPlacePredictions(
      {
        input,
        componentRestrictions: { country: 'es' },
        locationBias: bias
      },
      (predictions) => this.handlePredictions(predictions),
    );
  }

  onClick(place) {
    switch(place.place_id) {
      case 'located':
        this.selectManual(this.geolocatedLocation)
        return
      default:
        this.places.getDetails({
          placeId: place.place_id,
          language: 'es',
          fields: [
            'place_id',
            'name',
            'address_components',
            'geometry',
            'formatted_address',
            'types'
          ],
        },
        (detailedPlace) => {
          this.selectSearchResult({ ...detailedPlace, secondary_text: place.secondary_text })
        })
        return
    }
  }

  handlePredictions(predictions) {
    this.resultados = [{
      place_id: "located",
      matched: this.translateService.instant("search.your_location"),
      secondary_text: this.geolocatedLocation?.nombre_largo
    }];

    if (predictions?.length > 0) {
      for (var i = 0, prediction; prediction = predictions[i]; i++) {
        const matched_offset = prediction.structured_formatting.main_text_matched_substrings[0].offset
        const matched_length = prediction.structured_formatting.main_text_matched_substrings[0].length
  
        this.resultados.push({
          place_id: prediction.place_id,
          secondary_text: prediction.structured_formatting.secondary_text,
          non_matched_prefix: prediction.structured_formatting.main_text.slice(0, matched_offset),
          matched: prediction.structured_formatting.main_text.slice(matched_offset, matched_offset + matched_length),
          non_matched_suffix: prediction.structured_formatting.main_text.slice(matched_offset + matched_length),
        })
      }
    }

    this.ref.detectChanges()
  }

  onPressStart(favorite): void {
    this.isPressing = true;
    this.timeout = setTimeout(() => {
      if (this.isPressing) {
        this.ngZone.run(() => this.onLongPress(favorite));
      }
    }, this.pressDuration);
  }

  onPressEnd(): void {
    this.isPressing = false;
    clearTimeout(this.timeout);
  }

  onLongPress(favorite): void {
    this.manageFavorites.emit(favorite)
  }
}
