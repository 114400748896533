import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MyWaiisComponent } from '@components/my-waiis/my-waiis.component';
import { ModalController, Platform } from '@ionic/angular';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { SafeArea } from 'capacitor-plugin-safe-area';

export const breakpointsPx = [110, 296]

@Injectable({
  providedIn: 'root'
})
export class MyWaiisService {
  modal
  open = new BehaviorSubject<boolean>(false)
  breakpoints = [breakpointsPx[0] / this.platform.height(), 1]
  
  currentBreakpoint = new BehaviorSubject<number>(this.breakpoints[0])

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private authService: AuthenticationService,
    private platform: Platform
  ) {
    SafeArea.getSafeAreaInsets().then((response) => {
      this.authService.isAuthenticated.subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.breakpoints = [breakpointsPx[0] / this.platform.height(), (this.platform.height() - response.insets.top) / this.platform.height()]
          this.modal.setBreakpoints(this.breakpoints)
        } else {
          this.breakpoints = [breakpointsPx[0] / this.platform.height()]
          this.modal.setBreakpoints(this.breakpoints)
        }
      })
    });


    this.router.events.subscribe((navigationEvent: NavigationEnd) => {
      if (navigationEvent instanceof NavigationEnd && navigationEvent.url !== '/') {
        this.dismiss()
      }
    })
  }

  async openModal() {
    this.modal = await this.modalCtrl.create({
      component: MyWaiisComponent,
      breakpoints: this.breakpoints,
      initialBreakpoint: this.breakpoints[0],
      backdropDismiss: false,
      handle: false,
      id: "myWaiisModal",
      showBackdrop: false
    });
    this.modal.present();
    this.open.next(true)

    this.modal.addEventListener('ionBreakpointDidChange', (event) => {
      const breakpoint = event.detail.breakpoint
      this.currentBreakpoint.next(breakpoint)
      document.getElementById('myWaiisModal').style.setProperty('--border-radius', breakpoint === 1 ? '0' : '28px')
    });

    await this.modal.onWillDismiss();
    this.open.next(false)
  }

  changeBreakpoint() {
    if (this.currentBreakpoint.value === this.breakpoints[0]) {
      this.modal.setCurrentBreakpoint(this.breakpoints[1])
    } else {
      this.modal.setCurrentBreakpoint(this.breakpoints[0])
    }
  }

  dismiss() {
    if (this.modal) {
        this.modal.dismiss();
    }
  }
}