import { BehaviorSubject, Subject } from 'rxjs';
import { Geolocation, Position } from '@capacitor/geolocation';

import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import Ubicacion from '@app/models/Ubicacion';
import { TranslateService } from '@services/translate/translate.service';
import BackgroundGeolocation, {
  Location
} from "@transistorsoft/capacitor-background-geolocation";
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Preferences } from '@capacitor/preferences';
import { environment } from '@envs';

import { CapacitorHttp } from "@capacitor/core";
import { throttle } from '@app/utils'
import { LocationPermissions2Service } from '@services/modals/locationPermissions2/location-permissions2.service';
import { NativeGeocoder } from '@capgo/nativegeocoder';

declare type Coords = {
  latitude: number;
  longitude: number;
  accuracy: number;
  speed?: number;
};

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  public location = new BehaviorSubject<Ubicacion | undefined>(null);

  permission = new Subject<boolean>()
  mapPositionLocated = new BehaviorSubject<boolean>(false)
  shouldCenterMap = true

  locatedOrigin = new BehaviorSubject<boolean>(true)

  userLocations = new BehaviorSubject<{
    lat: number;
    lng: number;
    captured_at: string;
  }[]>([])

  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    private authentication: AuthenticationService,
    private locationPermissionsModal2: LocationPermissions2Service,
  ) {
    this.userLocations.subscribe(userLocations => {
      if (userLocations.length > 0) {
        throttle(() => this.sendUserLocations(), 60000)
      }
    })

    this.permission.subscribe((permission) => {
      if (this.platform.is('iphone')) {
        this.watchBackgroundPosition()
      }
    })
  }

  watchPosition() {
    Geolocation.watchPosition({
      enableHighAccuracy: true,
    }, (position) => {
      if (position) {
        throttle(() => this.updatePosition(position.coords), 5000)
      }
    })
  }

  checkPermissions() {
    Geolocation.checkPermissions().then(result => {
      if (result.location === "granted") {
        this.permission.next(true)
      } else {
        if (!this.platform.is('mobileweb')) {
          this.locationPermissionsModal2.openModal()
        }
      }
    })
  }

  initializeService() {
    Geolocation.checkPermissions().then(result => {
      if (result.location === "granted") {
        this.permission.next(true)
      }
    })

    this.permission.subscribe(async (permission) => {
      if (permission) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        }).then((position: Position) => {
          this.updatePosition(position.coords)
          this.mapPositionLocated.next(true)
        })
        this.watchPosition()
      }
    })
  }

  locality: string;
  address: string = "";

  watchBackgroundPosition() {
    BackgroundGeolocation.onLocation((location: Location) => {
      if (!location.sample) {
        this.userLocations.next([
          ...this.userLocations.getValue(),
          {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
            captured_at: new Date().toISOString()
          }
        ])
      }
    })

    BackgroundGeolocation.ready({
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: 200,
      stopTimeout: 5,
      stopOnTerminate: false,
      startOnBoot: true,
      showsBackgroundLocationIndicator: false
    }).then((state) => {
      BackgroundGeolocation.start()
    });
  }

  async updatePosition(position: Coords) {
    NativeGeocoder.reverseGeocode({latitude: position.latitude, longitude: position.longitude, apiKey: environment.mapsKey }).then(({ addresses }) => {
      let nombre_largo = this.translateService.instant("search.your_location");
      if (addresses.length > 0) {
        nombre_largo = `${addresses[0].thoroughfare}, ${addresses[0].subThoroughfare}, ${addresses[0].postalCode} ${addresses[0].locality}, ${addresses[0].administrativeArea}, ${addresses[0].countryName}`;
      }

      this.location.next({
        nombre: this.translateService.instant("search.your_location"),
        nombre_largo,
        locality: addresses[0]?.locality,
        coords: {
          lat: position.latitude,
          lng: position.longitude,
          precision: position.accuracy,
          speed: position.speed
        },
        place_id: 'located'
      });
    })
  }

  sendUserLocations() {
    try {
      if (this.authentication.isAuthenticated.getValue()) {
        Preferences.get({ key: 'token' }).then(async (token) => {
          const options = {
            url: environment.apiUrl + '/user-locations/bulk',
            headers: {
              Authorization: "Bearer " + token.value,
              'Content-Type': 'application/json'
            },
            data: {
              locations: this.userLocations.getValue()
            },
          };

          const response = await CapacitorHttp.post(options);
          this.userLocations.next([])
        })
      }
    } catch (error) {
      console.warn("error", error)
    }
  }
}
