import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { RoutinesService } from '@services/routines/routines.service';
import { TranslateService } from '@services/translate/translate.service';
import { UserService } from '@services/user/user.service';
import dayjs from 'dayjs';
import { CalendarComponent as IonicCalendarComponent } from 'ionic6-calendar';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  eventSource: any = [];
  @ViewChild(IonicCalendarComponent) calendar: IonicCalendarComponent;

  selectedMonth: string;
  selectedYear: string;
  currentYear = new Date().getFullYear().toString();
  today = dayjs();
  language = this.translate.getCurrentLang();

  lastSelectedDate: Date;

  @Output() loadEvents = new EventEmitter<Date>();

  constructor(
    private userService: UserService,
    private routines: RoutinesService,
    private translate: TranslateService
  ) {
    this.userService.calendarEvents.subscribe((events) => {
      this.updateEventSource(events);
    });

    this.routines.routines.subscribe((_) => {
      if (this.eventSource) {
        this.updateEventSource(this.eventSource);
      }
    });

    this.translate.onLangChange.subscribe((lang) => {
      this.language = lang.lang;
    });
  }

  updateEventSource(events) {
    this.eventSource = events?.map((event) => {
      return {
        ...event,
        title: this.getRoutineName(event) || 'Sin nombre',
      };
    });
  }

  getRoutineName(event) {
    return this.routines.routines
      .getValue()
      .find((routine) => routine.id === event.data.pooling_routine_id)?.name;
  }

  prevMonth() {
    this.calendar.slidePrev();
  }

  nextMonth() {
    this.calendar.slideNext();
  }

  onViewTitleChanged(title: string) {
    this.extractMonthFromTitle(title);
  }

  onCurrentDateChanged(date) {
    if (date.getMonth() !== this.lastSelectedDate?.getMonth()) {
      this.loadEvents.emit(date);
      this.lastSelectedDate = date;
    }
  }

  extractMonthFromTitle(title: string) {
    const [month, year] = title.split(' ');
    this.selectedMonth = month;
    this.selectedYear = year;
  }

  getEventStatus(event) {
    const isBefore = dayjs(event.endTime).isBefore(this.today);
    if (!isBefore) {
      return 'pending';
    } else {
      return event.data.status;
    }
  }

  ngOnInit() {}
}
