import { Injectable } from '@angular/core';
import { ComparadorComponent } from '@components/comparador/comparador.component';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { MyWaiisService } from '../my-waiis/my-waiis.service';

@Injectable({
  providedIn: 'root'
})
export class ComparadorService {
  modal
  breakpoints = [0, 0.45, 0.7, 1]
  screenHeight: number
  open = new BehaviorSubject<boolean>(false)
  currentBreakpoint = new BehaviorSubject<number>(0.7)

  shouldOpenMyWaiisOnDismiss = true

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private MyWaiisService: MyWaiisService
  ) {
    platform.ready().then(() => {
      this.screenHeight = platform.height()
    });

    this.currentBreakpoint.subscribe(currentBreakpoint => {
      this.modal?.setCurrentBreakpoint(currentBreakpoint)
    })
  }

  activeType = new BehaviorSubject<'rider' | 'driver' | 'public'>('rider')

  changeType = (type: 'rider' | 'driver' | 'public') => {
    this.activeType.next(type)
  }

  async openModal() {
    this.modal = await this.modalCtrl.create({
      component: ComparadorComponent,
      breakpoints: this.breakpoints,
      initialBreakpoint: 0.7,
      backdropDismiss: true,
      id: "modalComparador",
      cssClass: this.platform.is("android") && "android"
    });
    this.modal.present();
    this.open.next(true)
    this.setShouldOpenMyWaiisOnDismiss(true)

    await this.modal.onWillDismiss();
    this.onDismiss()
  }

  onDismiss() {
    this.open.next(false)
    if (this.shouldOpenMyWaiisOnDismiss) {
      this.MyWaiisService.openModal()
    }
  }

  setShouldOpenMyWaiisOnDismiss(shouldOpenMyWaiisOnDismiss: boolean) {
    this.shouldOpenMyWaiisOnDismiss = shouldOpenMyWaiisOnDismiss
  }

  dismiss() {
    this.modal?.dismiss()
  }
}
