import { BehaviorSubject } from 'rxjs';

import { AxiosService } from '../axios/axios.service';
import { FamiliasService } from '@services/familias/familias.service';
import { Injectable } from '@angular/core';
import { RouteService } from '@services/route/route.service';
import { ToastService } from '@services/toast/toast-service';
import { Preferences } from '@capacitor/preferences';
import { ViajesService } from '@services/viajes/viajes.service';
import { TranslateService } from '@services/translate/translate.service';
import { ComparadorService } from '@services/modals/comparador/comparador.service';
import Activo, { Type } from '@app/models/Activo';
import { environment } from '@envs';
import { doneTripStatus, tripStatuses } from '@app/constants';
import { PusherService } from '@services/pusher/pusher.service';
import { GeolocationService } from '@services/geolocation/geolocation.service';

declare const Pusher: any;

export const acceptedCities = ['Barcelona', 'Madrid']

export const families_names = {
  taxi: 'Taxi/VTC',
  bici: 'Bici',
  moto: 'Moto',
  patinete: 'Patinete',
  transporte_publico: 'Transporte público',
}

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  public activos = new BehaviorSubject<{[key in Type]: Activo[]}>({
    rider: [],
    driver: [],
    public: [],
  });

  selectedActivo = new BehaviorSubject<any>(null)

  requested = new BehaviorSubject<boolean>(false)

  enRuta = new BehaviorSubject<boolean>(false)

  userLocation
  originLocation

  constructor(
    private familiasService: FamiliasService,
    private axios: AxiosService,
    private routeService: RouteService,
    private toastService: ToastService,
    private viajesService: ViajesService,
    private translateService: TranslateService,
    private comparador: ComparadorService,
    private pusher: PusherService,
    private geolocation: GeolocationService
  ) {
    this.familiasService.familiasActivas.subscribe(_ => {
      this.getAssets()
    })
  }

  resetActivos() {
    this.activos.next({
      rider: [],
      driver: [],
      public: [],
    });
  }

  saveRoutePoints(origin, destination) {
    Preferences.set({ key: 'origin', value: JSON.stringify(origin) })
    Preferences.set({ key: 'destination', value: JSON.stringify(destination) })
  }

  async getAssets() {
    this.geolocation.checkPermissions()
    const familias = this.familiasService.familiasActivas.getValue()
    const origin = this.routeService.origen.getValue()
    const destiny = this.routeService.destino.getValue()
    const currentTrip = this.viajesService.currentTrip.getValue()

    if (!familias?.length || familias.length === 0 || !origin || !destiny || (currentTrip && !doneTripStatus.includes(currentTrip?.state) && currentTrip?.state !== tripStatuses.created)) {
      return;
    }

    this.comparador.openModal()

    this.saveRoutePoints(origin, destiny)

    this.requested.next(true)
    this.enRuta.next(true)

    await this.resetActivos()

    let activosJson = [];

    familias.forEach((famila) => {
      activosJson.push({ nombre: famila });
    });

    const bodyRequest = {
      origen: {
        lat: origin.coords.lat,
        lng: origin.coords.lng,
        locality: origin.locality,
        types: origin.types,
        long_address: origin.nombre_largo
      },
      destino: {
        lat: destiny.coords.lat,
        lng: destiny.coords.lng,
        locality: destiny.locality,
        types: destiny.types,
        long_address: destiny.nombre_largo
      },
      familias: [
        { "nombre": "taxi" },
        { "nombre": "moto" },
        { "nombre": "bici" },
        { "nombre": "patinete" },
        { "nombre": "transporte_publico" },
        { "nombre": "coche" }
      ],
      tarifa_type: "closed"
    };

    (await this.axios.getInstance())
      .post('/request-activos', bodyRequest)
      .then(async (response) => {
        const channel = this.pusher.pusher.subscribe(`cache-request_activo.${response.data.identifier}`)

        channel.bind_global(async (_, data) => {
          if (data.activos && data.activos.precio) {
            const activo = await this.formatActivos(data.activos, bodyRequest.origen, bodyRequest.destino)
            this.activos.next({
              ...this.activos.getValue(),
              [activo.type]: [...this.activos.getValue()[activo.type], activo]
            })
          }
        });

        setTimeout(() => this.requested.next(false), 30000)
      })
      .catch((error) => {
        console.warn("error", error)
        this.requested.next(false)
        const errorMessage = Object.values(error.response.data.errors)[0][0]
        this.toastService.presentToast(this.translateService.instant(`errors.${errorMessage}`), "error")
      })
  }

  async getDirections(uuid: string) {
    return (await this.axios.getInstance())
      .get(`/activo/${uuid}/directions`,)
      .then(async (response) => {
        return response.data
      })
  }

  async formatActivos(activo, origin, destination): Promise<Activo> {
    let formatedActivo = activo;

    const directions = (await this.getDirections(activo.id))

    formatedActivo = {
      ...formatedActivo,
      directions: directions.directions,
      data: directions[2],
      origin,
      destination
    }

    if (formatedActivo.familia.name === "transporte_publico") {
      formatedActivo = await this.formatTransportePublicoActivo(formatedActivo);
    }

    if (
      formatedActivo.operador.name === "taxi033" ||
      formatedActivo.operador.name === "cooltra"
    ) {
      formatedActivo = {
        ...formatedActivo,
        priority: 9
      }
    } else {
      formatedActivo = {
        ...formatedActivo,
        priority: 0
      }
    }

    if (
      formatedActivo.familia.name === "taxi" && formatedActivo.operador.name !== "taxi033" ||
      formatedActivo.familia.name === "moto" && formatedActivo.operador.name !== "cooltra" ||
      formatedActivo.familia.name === "bici" && formatedActivo.operador.name !== "cooltra"
    ) {
      formatedActivo = {
        ...formatedActivo,
        estimated: true
      }
    } else {
      formatedActivo = {
        ...formatedActivo,
        estimated: false
      }
    }

    const type = this.getActivoType(formatedActivo)

    formatedActivo = {
      ...formatedActivo,
      type
    }

    return formatedActivo;
  }

  getActivoType(activo) {
    switch(activo.familia.name) {
      case 'moto':
      case 'patinete':
        return 'driver';
      case 'coche': {
        if (activo.operador.name === "car-pooling") {
          return activo.type ? activo.type : 'rider';
        }
        return 'driver';
      }
      case 'taxi':
        return 'rider';
      case 'transporte_publico':
        return 'public';
      case 'bici':
        return 'driver';
    }
  }

  async formatTransportePublicoActivo(activo) {
    let icons: { icon: string, zIndex: string }[] = []
    const steps = this.routeService.flatternSteps(this.routeService.flatternDirections([...activo.directions.rider_to_origin, activo.directions.origin_to_destination]).routes[0].legs)
    steps.map((step, index) => {
      if (step.travel_mode === "TRANSIT") {
        const icon = 'https://' + (step.transit_details.line.vehicle.local_icon || step.transit_details.line.vehicle.icon)
        icons.push({ icon, zIndex: `z-[${90 - index}]` })
      }
    })

    const formatedActivo = {
      ...activo,
      icons
    }

    return formatedActivo;
  }

  formatNumber(value) {
    return parseFloat(String(value).replace(/,/g, ''))
  }

  selectActivo(activo) {
    this.selectedActivo.next(activo)
  }

  reset() {
    this.resetActivos()

    this.requested.next(false)
    this.enRuta.next(false)
    this.userLocation = undefined
    this.originLocation = undefined
    this.selectedActivo.next(null)

    Preferences.remove({ key: 'origin' })
    Preferences.remove({ key: 'destination' })
  }
}