import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { Capacitor } from "@capacitor/core";
import { Component, NgZone } from '@angular/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { UserService } from '@services/user/user.service'
import { environment } from '@envs'
import { TranslateService } from '@services/translate/translate.service'
import { initializeApp } from 'firebase/app';
import { ResetServicesService } from '@services/resetServices/reset-services.service'
import { AnalyticsService } from '@services/analytics/analytics.service'
import { AuthenticationService } from '@services/authentication/authentication.service'
import { OtaUpdatesService } from '@services/otaUpdates/ota-updates.service'
import { Platform } from '@ionic/angular';
import { Stripe } from '@capacitor-community/stripe';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StoreUpdatesService } from '@services/storeUpdates/store-updates.service'
import { FamiliasService } from '@services/familias/familias.service'
import { ChinchetasService } from '@services/chinchetas/chinchetas.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private resetServices: ResetServicesService,
    private analytics: AnalyticsService,
    private authService: AuthenticationService,
    private otaUpdates: OtaUpdatesService,
    private platform: Platform,
    private storeUpdates: StoreUpdatesService,
    private zone: NgZone,
    private familias: FamiliasService,
    private _: ChinchetasService
  ) {
    this.initializeApp()
    this.authService.initializeGoogleAuth()
    this.translate.initializeService()
    this.resetServices.initializeService()
    Stripe.initialize({
      publishableKey: environment.stripeKey,
    });
    this.initializeFirebase();
    if (!this.platform.platforms().includes("mobileweb") && !this.platform.platforms().includes('desktop')) {
      this.storeUpdates.checkForUpdates()
    }
  }

  async ngOnInit() {
    if (this.platform.platforms().includes('android')) {
      this.otaUpdates.checkVersion()
    }
    await SplashScreen.hide();

    this.userService.user.subscribe(user => {
      if (user) {
        this.analytics.initializeAnalytics(user)
      }

      this.router.events.subscribe((navigationEvent: NavigationEnd | NavigationStart) => {
        if (navigationEvent instanceof NavigationEnd) {
          this.analytics.logEvent('navigated', { route: navigationEvent.url })
        }
      })
    })
  }

  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebaseConfig);
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(event.url)

        const path = url.pathname
        const params = paramsToObject(url.searchParams.entries())

        this.router.navigate([path, params]).then(_ => {})
        .catch(_ => {
          this.router.navigate(['/'])

          if (path.startsWith('/cooltra')) {
            this.familias.disableFamilia('taxi')
            this.familias.disableFamilia('bici')
            this.familias.disableFamilia('patinete')
            this.familias.disableFamilia('transporte_publico')
            this.familias.enableFamilia('moto')
          }
        });
      })
    });
  }
}

function paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
