import { AuthenticationService } from '@services/authentication/authentication.service'
import { AxiosService } from '@services/axios/axios.service'
import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'
import Preferencias from '@app/models/Preferencias'
import { Router } from '@angular/router'
import User from '@app/models/User'
import { ToastService } from '@services/toast/toast-service'
import { TranslateService } from '@ngx-translate/core'
import dayjs from 'dayjs'
import { Preferences } from '@capacitor/preferences'
import { NativeGeocoder } from '@capgo/nativegeocoder'
import { environment } from '@envs'
import { NotificationsService } from '@services/notifications/notifications.service'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  preferences = new BehaviorSubject<Preferencias>(null)
  user = new BehaviorSubject<User>(null)
  balance = new BehaviorSubject<number>(null)
  couponsBalance = new BehaviorSubject<number>(null)
  addCouponError = new BehaviorSubject<string>(null)
  favoriteAddresses = new BehaviorSubject<any[]>([])

  transactions = new BehaviorSubject<any[]>([])

  stripeOnboardingLink = new BehaviorSubject<string>(null)

  stripeAccount = new BehaviorSubject<{}>({})

  calendarEvents = new BehaviorSubject<any[]>([])

  carpoolingData = new BehaviorSubject<{
    expenses_saved: number
    balance_in_waiis: number
    total_routes: number
    completed_routes: number
    co2_saved_driver: number
    co2_saved_rider: number
    previous_balance_in_waiis: number
    previous_expenses_saved: number
    previous_co2_saved_driver: number
    previous_co2_saved_rider: number
    percentage_diff_expenses_saved: number
    percentage_diff_balance_in_waiis: number
    percentage_diff_co2_saved_driver: number
    percentage_diff_co2_saved_rider: number
    percentage_diff_routes_completed: number
  }>({
    expenses_saved: 0,
    balance_in_waiis: 0,
    total_routes: 0,
    completed_routes: 0,
    co2_saved_driver: 0,
    co2_saved_rider: 0,
    previous_balance_in_waiis: 0,
    previous_expenses_saved: 0,
    previous_co2_saved_driver: 0,
    previous_co2_saved_rider: 0,
    percentage_diff_expenses_saved: 0,
    percentage_diff_balance_in_waiis: 0,
    percentage_diff_co2_saved_driver: 0,
    percentage_diff_co2_saved_rider: 0,
    percentage_diff_routes_completed: 0
  })

  isWalkthroughSeen = false

  constructor(
    private axios: AxiosService,
    private auth: AuthenticationService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private notifications: NotificationsService
  ) {
    Preferences.get({ key: "userData" }).then(value => {
      if (value.value) {
        this.user.next(JSON.parse(value.value))
      }
    })

    Preferences.get({ key: 'balance' }).then(value => {
      if (value.value) {
        this.balance.next(parseFloat(value.value))
      }
    })

    Preferences.get({ key: 'couponsBalance' }).then(value => {
      if (value.value) {
        this.couponsBalance.next(parseFloat(value.value))
      }
    })

    this.auth.isAuthenticated.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.getPreferences()
        this.getUser()
        this.getBalance();
        this.getUserFavoriteAddresses()
        this.getCalendarEvents()
        this.notifications.initializeService()
      } else if (isAuthenticated !== null && !this.isWalkthroughSeen) {
        this.router.navigate(['/walkthrough'])
        this.isWalkthroughSeen = true
      }
    })
  }

  async getUser(valorations: boolean = false) {
    const url = valorations ? '/users/me?with=user_valorations,user_reviews' : '/users/me';

    (await this.axios.getInstance())
      .get(url)
      .then(response => {
        Preferences.set({ key: "userData", value: JSON.stringify(response.data) })
        this.user.next(response.data)
        if (!response.data.is_walkthrough_seen && !this.isWalkthroughSeen) {
          this.router.navigate(['/walkthrough'])
        }
        this.isWalkthroughSeen = true
      })
  }

  async getPreferences() {
    (await this.axios.getInstance())
      .get('/users/preferences')
      .then(response => {
        this.preferences.next(response.data)
      })
  }

  async setPreferences(preferences: Preferencias) {
    (await this.axios.getInstance())
      .patch('/users/preferences', preferences)
      .then(response => { this.preferences.next(response.data) })
  }

  async saveDNI(dni_front: File, dni_back: File) {
    const formData = new FormData();
    formData.append('dni_front', dni_front);
    formData.append('dni_back', dni_back);

    (await this.axios.getInstance())
      .post('/users/dni', formData)
      .then(response => {
        this.user.next(response.data)
      })
  }

  async saveDriverLicense(driverLicenseFront: File, driverLicenseBack: File) {
    const formData = new FormData();
    formData.append('carnet_front', driverLicenseFront);
    formData.append('carnet_back', driverLicenseBack);

    (await this.axios.getInstance())
      .post('/users/carnet', formData)
      .then(response => {
        this.user.next(response.data)
      })
  }

  async saveSelfie(selfie: File) {
    const formData = new FormData();
    formData.append('selfie', selfie);

    (await this.axios.getInstance())
      .post('/users/selfie', formData)
      .then(response => {
        this.user.next(response.data)
      })
  }

  async passwordRecovery(email: string) {
    (await this.axios.getInstance())
      .post('/forgot-password', { email })
      .then(response => {
        this.user.next(response.data)
      })
  }

  async changeEmail(email: string) {
    (await this.axios.getInstance())
      .put('/users/email', { email })
      .then(response => {
        this.user.next(response.data)
      })
  }

  async requestEmailVerification() {
    (await this.axios.getInstance())
      .post('/users/email/verification-notification')
  }

  async forgotPassword(email: string) {
    (await this.axios.getInstance())
      .post('/forgot-password', { email })
      .then(response => {
        if (response.data === 1) {
          this.auth.logout()
          this.router.navigate(['/login'])
        }
      })
  }

  async changeName(name: string, surname: string) {
    (await this.axios.getInstance())
      .patch('/users', { name, surname })
      .then(response => {
        this.user.next(response.data)
      })
  }

  async deleteAccount() {
    (await this.axios.getInstance())
      .get('/users/delete')
      .then(() => {
        this.auth.logout()
        this.router.navigate(['/'])
      })
  }

  async setHomeAddress(address) {
    (await this.axios.getInstance())
      .post('/users/address/home', address)
      .then(response => this.user.next(response.data))
  }

  async setWorkAddress(address) {
    (await this.axios.getInstance())
      .post('/users/address/work', address)
      .then(response => this.user.next(response.data))
  }

  async getBalance() {
    (await this.axios.getInstance())
      .get('/users/me/balance')
      .then(response => {
        this.balance.next(response.data.account_balance)
        this.couponsBalance.next(response.data.current_balance.available[0].amount)

        Preferences.set({ key: 'balance', value: response.data.account_balance })
        Preferences.set({ key: 'couponsBalance', value: response.data.current_balance.available[0].amount })
      })
  }

  async getTransactions() {
    (await this.axios.getInstance())
      .get('/users/me/stripe/balance-transactions')
      .then(response => {
        const transactions = [
          ...response.data.charges.data,
          ...response.data.payouts.data,
          ...response.data.transactions
        ]
        this.transactions.next(transactions.sort((a, b) => b.created - a.created))
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.getStripeOnboardingLink()
        }
      })
  }

  async getStripeOnboardingLink() {
    (await this.axios.getInstance())
      .get('/users/me/stripe-connect-setup-link')
      .then(response => {
        this.stripeOnboardingLink.next(response.data.link)
      })
  }

  async verifyStripeAccount() {
    return (await this.axios.getInstance())
      .post('/users/me/stripe-connect-setup-link/verify-and-setup-manual-payouts')
      .then(response => {
        this.stripeAccount.next(response.data)

        if (response.data.stripe_enabled) {
          this.stripeOnboardingLink.next(null)
        }
        return response.data
      })
  }

  async addBalance(coupon: string, travel_id?: string) {
    return (await this.axios.getInstance())
      .post('/users/me/balance/exchange-coupon', { coupon, travel_id })
      .then(response => {
        this.balance.next(-response.data.user_balance.account_balance) // balance stripe
        // this.balance.next(response.data.balance_user_snapshot.after_amount)
      })
  }

  async updateUserInfo(data: {
    name?: string
    surname?: string
    description?: string
    birth_date?: string
  }) {
    return (await this.axios.getInstance())
      .put('/users/me', data)
      // .post('/users/me', data)
      .then(response => {
        this.user.next(response.data)
        console.warn(response)
      })
  }

  async getUserFavoriteAddresses() {
    (await this.axios.getInstance())
      .get('/users/me/favorite-addresses')
      .then(response => {
        response.data.data.forEach(async address => {
          this.favoriteAddresses.next([])
          await this.geocode(address)
        })
      })
  }

  async geocode(favoriteAddress) {
    NativeGeocoder.reverseGeocode({latitude: parseFloat(favoriteAddress.lat), longitude: parseFloat(favoriteAddress.lng), apiKey: environment.mapsKey }).then(({ addresses }) => {
      let address = favoriteAddress.name;
      if (addresses.length > 0) {
        address = `${addresses[0].thoroughfare}, ${addresses[0].subThoroughfare}, ${addresses[0].postalCode} ${addresses[0].locality}, ${addresses[0].administrativeArea}, ${addresses[0].countryName}`;
      }

      this.favoriteAddresses.next([
        ...this.favoriteAddresses.value,
        {
          nombre: address,
          nombre_largo: address,
          locality: addresses[0]?.locality,
          coords: {
            lat: parseFloat(favoriteAddress.lat),
            lng: parseFloat(favoriteAddress.lng)
          },
          place_id: "favorite",
          ...favoriteAddress
        }
      ])
    })
  }

  async addFavoriteAddress(addressCoords, type, name) {
    const body = {
      lat: addressCoords.lat,
      lng: addressCoords.lng,
      name: name,
      type: type,
    };

    (await this.axios.getInstance())
      .post('/users/me/favorite-addresses', body)
      .then(response => {
        this.geocode(response.data.data)

        this.toast.presentToast(this.translate.instant('favorites.favorite_address_added'), 'info')
      })
      .catch((error) => {
        if (error.response.data.message) {
          this.toast.presentToast(error.response.data.message, 'error')
        } else {
          this.toast.presentToast(this.translate.instant('favorites.favorite_address_add_error'), 'error')
        }
      })
  }

  async editFavoriteAddress(addressId, addressCoords, type, name) {
    const body = {
      lat: addressCoords.lat,
      lng: addressCoords.lng,
      name: name,
      type: type,
    };

    (await this.axios.getInstance())
      .put(`/users/me/favorite-addresses/${addressId}`, body)
      .then((response) => {
        const favorites = this.favoriteAddresses.value.filter(favorite => favorite.id !== addressId)
        this.favoriteAddresses.next(favorites)
        this.geocode(response.data.data)
        this.toast.presentToast(this.translate.instant('favorites.favorite_address_updated'), 'info')
      })
      .catch(() => {
        this.toast.presentToast(this.translate.instant('favorites.favorite_address_update_error'), 'error')
      })
  }

  async deleteFavoriteAddress(addressId) {
    (await this.axios.getInstance())
      .delete(`/users/me/favorite-addresses/${addressId}`)
      .then(() => {
        const favorites = this.favoriteAddresses.value.filter(favorite => favorite.id !== addressId)
        this.favoriteAddresses.next(favorites)
        this.toast.presentToast(this.translate.instant('favorites.favorite_address_deleted'), 'info')
      })
      .catch(() => {
        this.toast.presentToast(this.translate.instant('favorites.favorite_address_delete_error'), 'error')
      })
  }

  async getCalendarEvents(start_date?: string, end_date?: string) {
    start_date = start_date ? start_date : dayjs().startOf('month').format('YYYY-MM-DD')

    end_date = end_date ? end_date : dayjs().endOf('month').format('YYYY-MM-DD')

    ;(await this.axios.getInstance())
      .get(`/users/me/pooling-calendar?start_date=${start_date}&end_date=${end_date}`)
      .then(response => {
        this.calendarEvents.next([])

        response.data.data.forEach(event => {
          this.calendarEvents.next([
            ...this.calendarEvents.value,
            this.formatEvent(event)
          ])
        })
      })
  }

  async walkthroughSeen() {
    (await this.axios.getInstance())
      .post('/users/me/walkthrough-seen')

    this.router.navigate(['/'])
  }

  async getPoolingData({
    startDate = '2024-01-01',
    endDate
  }: {
    startDate?: string
    endDate?: string
  } = {}) {
    endDate = endDate || new Date().toISOString().split('T')[0];

    (await this.axios.getInstance())
      .get(`/users/me/pooling-data?start_date=${startDate}&end_date=${endDate}`)
      .then(({ data }) => {
        this.carpoolingData.next(data)
      })
  }

  formatEvent(event) {
    return {
      title: "Test",
      startTime: new Date(`${event.start_date}T${event.departure_time}`),
      endTime: new Date(`${event.start_date}T${event.arrival_time}`),
      data: event
    }
  }

  reset() {
    this.preferences.next(null)
    this.user.next(null)
    Preferences.remove({ key: 'userData' })
  }
}
