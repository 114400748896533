import { Injectable } from '@angular/core';
import Vehicle from '@app/models/Vehicle';
import { AxiosService } from '@services/axios/axios.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyVehiclesService {
  vehicles = new BehaviorSubject<any[]>([])
  vehicleBrands = new BehaviorSubject<any[]>([]);
  fuelTypes = new BehaviorSubject<any[]>([]);

  constructor(private axios: AxiosService) {
    this.getFuelTypes()
    this.getVehicleBrands()
    this.getVehicles()
  }

  async getVehicles() {
    (await this.axios.getInstance()).get('/vehicles').then(vehicles => {
      this.vehicles.next(vehicles.data.data)
    })
  }

  async addVehicle(vehicle: Vehicle) {
    return (await this.axios.getInstance()).post('/vehicles', vehicle).then(() => {
      this.vehicles.next([...this.vehicles.getValue(), vehicle])
    })
  }

  async updateVehicle(vehicle: Vehicle) {
    const body = {
      ...vehicle,
      vtc_identifier: undefined
    };

    return (await this.axios.getInstance()).put(`/vehicles/${vehicle.id}`, body).then(() => {
      const vehicles = this.vehicles.getValue()

      const vehicleIndex = vehicles.findIndex(v => v.id === vehicle.id)

      vehicles[vehicleIndex] = vehicle

      console.log("vehicles[vehicleIndex] = vehicle", vehicles[vehicleIndex] = vehicle)

      this.vehicles.next(vehicles)
    })
  }

  async deleteVehicle(vehicle_id: string): Promise<void> {
    const instance = await this.axios.getInstance();
    return instance.delete(`/vehicles/${vehicle_id}`).then(response => {
      if (response.status === 200) {
        const vehicles = this.vehicles.getValue();
        const vehicleIndex = vehicles.findIndex(v => v.id === vehicle_id);
        if (vehicleIndex !== -1) {
          vehicles.splice(vehicleIndex, 1);
          this.vehicles.next(vehicles);
        }
      }
    }).catch(error => {
      console.error('Error deleting vehicle', error);
    });
  }

  getVehicle(id: string) {
    return this.vehicles.getValue().find(v => v.id === id)
  }

  async getVehicleBrands(): Promise<void> {
    (await this.axios.getInstance())
      .get('/vehicle-brands')
      .then(response => {
        this.vehicleBrands.next(response.data.data)
      })
  }

  async getFuelTypes(): Promise<void> {
    (await this.axios.getInstance())
      .get('/fuel-types')
      .then(response => {
        this.fuelTypes.next(response.data.data)
      })
  }

}
