import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane'

import { GoogleMapService } from '@services/google-map/google-map.service';
import { Platform } from '@ionic/angular';
import { RouteService } from '@src/app/services/route/route.service'
import { UtilsService } from '@services/utils/utils.service'
import { ViajesService } from '@services/viajes/viajes.service';
import { ModalsService } from '@services/modals/modals.service';
import { PaymentMethodsService } from '@services/payment-methods/payment-methods.service';
import { VehiclesService } from '@services/vehicles/vehicles.service';
import Ubicacion from '@app/models/Ubicacion';
import { calculateDistance, launchApp, throttle } from '@app/utils';
import { ToastService } from '@services/toast/toast-service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Router } from '@angular/router';
import { GooglePayEventsEnum, Stripe as StripeSDK } from '@capacitor-community/stripe';
import { environment } from '@envs';
import { ChinchetasService } from '@services/chinchetas/chinchetas.service';
import { ComparadorService } from '@services/modals/comparador/comparador.service';
import { UserService } from '@services/user/user.service';
import { TranslateService } from '@services/translate/translate.service';
import { Keyboard } from '@capacitor/keyboard';
import { doneTripStatus, tripStatuses } from '@app/constants';

declare const Stripe

const operadoresConInfo = ['cooltra', 'seatmo', 'acciona', 'bicing']

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResumenComponent implements OnInit {
  activo: any
  @ViewChild('pedirViajeButton', { read: ElementRef, static: true }) pedirViajeButton: ElementRef

  stripe = Stripe(environment.stripeKey)

  selectedCard: number = 0
  cards: any[]

  requestingPayment: boolean = false

  originLocation: Ubicacion
  destinationLocation: Ubicacion
  isAuthenticated: boolean = false
  applePayAvailable: boolean = false
  paymentIntent
  needToOpenApp: boolean = false
  paid: boolean = false

  steps: any[];
  topHeight = this.platform.height() * 0.65
  middleHeight = this.platform.height() * 0.4
  @ViewChild('modalResumenContent', { read: ElementRef, static: true }) content: ElementRef
  modalResumenPresented: boolean = false
  modalResumen: CupertinoPane;
  modalResumenSettings: CupertinoSettings = {
    bottomClose: true,
    fastSwipeClose: false,
    handleKeyboard: false,
    buttonDestroy: false,
    parentElement: '#modalResumenContainer',
    dragBy: ["#modalResumenContainer .draggable", ".header"],
    breaks: {
      top: { enabled: true, height: this.topHeight },
      middle: { enabled: true, height: this.middleHeight },
    },
    initialBreak: "middle",
    events: {
      onWillDismiss: () => {
        this.balance = null
        this.card = null
        setTimeout(() => {
          if (this.paid) {
            this.pedirViajeButton.nativeElement.style.visibility = 'hidden'
          } else if (this.modalPayment.isHidden() || this.modalPayment.isHidden() === null) {
            this.routeService.selectActivo(null)
            this.pedirViajeButton.nativeElement.style.visibility = 'hidden'
            this.comparador.openModal()
            this.googleMapService.resetPath()
          }
        }, 300)
      },
      onDidPresent: () => {
        this.modalResumenPresented = true

        this.modalResumen.moveToBreak('middle')

        this.content.nativeElement.setAttribute("style", "overflow-y: auto !important")
        this.content.nativeElement.style.height = `${this.middleHeight - this.content.nativeElement.offsetTop}px`
      },
      onWillPresent: async () => {
        this.comparador.dismiss()

        const needToOpenAppOperatorNames = ['bolt', 'seatmo', 'cabify', 'cooltra', 'acciona', 'ambtmb', 'bicing', 'taxi_zaragoza', 'bizi', 'lime', 'bicimad', 'uber', 'goto']

        if (this.activo.operador.name === 'taxi033') {
          await this.createPaymentIntent()
        }

        if (needToOpenAppOperatorNames.includes(this.activo.operador.name)) {
          this.needToOpenApp = true
          this.changeDetector.detectChanges();
        }
      },
      onDrag: () => {
        this.content.nativeElement.style.height = `${window.screen.height - this.getPaneTransformY() - this.content.nativeElement.offsetTop
          }px`
        this.content.nativeElement.setAttribute("style", "overflow-y: hidden !important")
      },
      onTransitionEnd: () => {
        throttle(() => this.setHeight(), 200)
      },
    },
  }
  selectedStep = null
  lastTripState

  balance = null
  card = null

  modalPaymentPresented: boolean = false

  requested: boolean = false

  modalPayment: CupertinoPane
  modalPaymentSettings: CupertinoSettings = {
    parentElement: 'body',
    bottomClose: true,
    fastSwipeClose: false,
    buttonDestroy: true,
    breaks: {
      top: {
        enabled: true,
        height: 400,
      },
      middle: {
        enabled: true,
        height: 400,
      },
      bottom: {
        enabled: true,
        height: 0,
      }
    },
    events: {
      onDidPresent: () => {
        this.modalPaymentPresented = true
      }
    }
  }

  constructor(
    private routeService: RouteService,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef,
    private platform: Platform,
    private googleMapService: GoogleMapService,
    private viajesService: ViajesService,
    private modalsService: ModalsService,
    private paymentMethodsService: PaymentMethodsService,
    private chinchetas: ChinchetasService,
    private vehiclesService: VehiclesService,
    private toastService: ToastService,
    private analytics: AnalyticsService,
    private auth: AuthenticationService,
    private router: Router,
    private comparador: ComparadorService,
    private userService: UserService,
    private translate: TranslateService,
  ) {
    this.modalsService.modalPayment.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          this.modalPayment.present(...modal?.params)
          break
        case 'moveToBreak':
          if (this.modalPaymentPresented) {
            this.modalPayment.moveToBreak(modal?.params[0])
          }
          break
        case 'hide':
          // this.pedirViajeButton.nativeElement.style.visibility = 'hidden'
          if (this.modalPaymentPresented) {
            this.modalPayment.hide()
          }
          break
        case 'destroy':
          // this.pedirViajeButton.nativeElement.style.visibility = 'hidden'
          this.modalPayment.destroy(...modal?.params)
          break
      }
    })

    this.modalsService.modalResumen.subscribe((modal) => {
      switch (modal?.action) {
        case 'present':
          // if (this.asset.familia.name !== 'transporte_publico') {
            this.pedirViajeButton.nativeElement.style.visibility = 'visible'
          // }
          this.modalResumen.present(...modal?.params)
          break
        case 'moveToBreak':
          if (this.modalResumenPresented) {
            this.modalResumen.moveToBreak(modal?.params[0])
          }
          break
        case 'hide':
          if (this.modalResumenPresented) {
            this.pedirViajeButton.nativeElement.style.visibility = 'hidden'
            this.modalResumen.hide()
          }
          break
        case 'destroy':
          if (this.modalResumenPresented) {
            this.pedirViajeButton.nativeElement.style.visibility = 'hidden'
            this.modalResumen.destroy(...modal?.params)
          }
          break
      }
    })

    this.viajesService.currentTrip.subscribe(currentTrip => {
      if (
        (!this.lastTripState || doneTripStatus.includes(this.lastTripState) || this.lastTripState === tripStatuses.created) &&
        !doneTripStatus.includes(currentTrip?.state) && currentTrip?.state !== tripStatuses.created
      ) {
        this.requestingPayment = false
        this.modalsService.destroyModal('modalResumen')
      }

      this.lastTripState = currentTrip?.state
    })

    this.paymentMethodsService.cards.subscribe(cards => this.cards = cards)
    this.paymentMethodsService.selectedCard.subscribe(selectedCard => this.selectedCard = selectedCard)
  }

  getPaneTransformY() {
    const translateYRegex = /\.*translateY\((.*)px\)/i
    const paneEl: HTMLElement = document.querySelector(".pane")
    return paneEl
      ? parseFloat(translateYRegex.exec(paneEl.style.transform)[1])
      : 0
  }

  setHeight() {
    this.content.nativeElement.setAttribute("style", "overflow-y: auto !important")
    if (this.modalResumen.currentBreak() === "top") {
      this.content.nativeElement.style.height = `${this.topHeight - this.content.nativeElement.offsetTop}px`
    }
    if (this.modalResumen.currentBreak() === "middle") {
      this.content.nativeElement.style.height = `${this.middleHeight - this.content.nativeElement.offsetTop}px`
    }
  }

  sortByDistance(a, b) {
    const locationOfA = new google.maps.LatLng(a.lat, a.lng)
    const locationOfB = new google.maps.LatLng(b.lat, b.lng)
    const locationOfOrigin = new google.maps.LatLng(this.originLocation.coords.lat, this.originLocation.coords.lng)

    const distanceToA = calculateDistance(locationOfA, locationOfOrigin)
    const distanceToB = calculateDistance(locationOfB, locationOfOrigin)

    if (distanceToA < distanceToB) {
      return -1
    } else if (distanceToA > distanceToB) {
      return 1
    }
    return 0
  }

  ngOnInit() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.modalResumen.moveToHeight(650)
    });

    Keyboard.addListener('keyboardWillHide', () => {
      let height
      if (this.modalResumen.currentBreak() === "top") {
        height = this.topHeight - this.content.nativeElement.offsetTop
      }
      if (this.modalResumen.currentBreak() === "middle") {
        height = this.middleHeight - this.content.nativeElement.offsetTop
      }
      this.modalResumen.moveToHeight(height)
    })

    this.modalResumen = new CupertinoPane(
      '.modalResumen',
      this.modalResumenSettings
    )

    this.modalPayment = new CupertinoPane(
      '.modalPayment',
      this.modalPaymentSettings
    )

    this.auth.isAuthenticated.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated

      this.changeDetector.detectChanges()
    })

    this.routeService.selectedActivo.subscribe(activo => {
      this.activo = activo

      this.originLocation = this.routeService.origen.getValue()
      this.destinationLocation = this.routeService.destino.getValue()

      if (activo) {
        if (
          operadoresConInfo.includes(activo.operador.name) ||
          activo.operador.name === 'cabify' && activo.familia.name === 'moto'
        ) {
          let asset

          const chinchetas = this.chinchetas.chinchetas.filter(chincheta => {
            return chincheta.familia === activo.familia.name && chincheta.operador === activo.operador.name
          })

          if (chinchetas.length > 0) {
            chinchetas.sort((a, b) => this.sortByDistance(a, b))

            asset = chinchetas[0]
            this.googleMapService.centerMap({ lat: asset.lat, lng: asset.lng }, true)
          } else {
            asset = {
              familia: activo.familia.name,
              operador: activo.operador.name,
              deeplink: activo.deeplink,
              precio: activo.precio,
              estimated: activo.estimated
            }
          }

          if (activo.familia.name !== "moto" || activo.operador.name !== "cooltra") {
            this.vehiclesService.selectActivo(asset)
          }
        } else {
          this.modalsService.presentModal('modalResumen')
        }
        this.updateRoute()
        this.changeDetector.detectChanges()
      } else if (this.modalResumenPresented && !this.modalResumen.isHidden()) {
        this.modalsService.hideModal('modalResumen')
      }
    })

    this.routeService.selectedStep.subscribe(step => {
      this.selectedStep = step
    })

    this.viajesService.currentTrip.subscribe(currentTrip => {
      if (doneTripStatus.includes(currentTrip?.state)) {
        this.modalsService.hideModal('modalPayment')
      }
    })
  }

  async payWithApple(stripeObject) {
    this.analytics.logEvent('pay_with_apple', {})

    await StripeSDK.createApplePay({
      paymentIntentClientSecret: stripeObject.stripe_client_secret,
      currency: "EUR",
      countryCode: 'ES',
      merchantIdentifier: 'merchant.eco.waiis.app',
      paymentSummaryItems: [{ label: `${this.activo.operador.name} (via WAIIS)`, amount: this.card / 100 }],
    });

    return await StripeSDK.presentApplePay()
  }

  async payWithGoogle(stripeObject) {
    this.analytics.logEvent('pay_with_google', {})

    await StripeSDK.createGooglePay({
      paymentIntentClientSecret: stripeObject.stripe_client_secret,
      countryCode: 'ES',
      currency: 'EUR',
    });

    const result = await StripeSDK.presentGooglePay();
    if (result.paymentResult === GooglePayEventsEnum.Completed) {
      return Promise.resolve()
    } else {
      return Promise.reject()
    }
  }

  async payWithCard(stripeObject) {
    this.analytics.logEvent('pay_with_card', {})

    const card = this.cards[this.selectedCard]
    await this.stripe.confirmCardPayment(stripeObject.stripe_client_secret, { payment_method: card.id })
  }

  updateRoute() {
    this.routeService.printPath(this.routeService.flatternDirections([this.activo.directions.origin_to_destination]).routes[0].legs)
    this.steps = this.routeService.flatternSteps(this.routeService.flatternDirections([this.activo.directions.origin_to_destination]).routes[0].legs)
  }

  formatTime = (tiempo) => {
    return this.utilsService.formatTime(tiempo)
  }

  formatPrice = (precio) => {
    return this.utilsService.formatPrice(precio)
  }

  formatCo2 = (co2) => {
    return this.utilsService.formatCo2(co2)
  }

  selectStep(step) {
    if (this.selectedStep !== step) {
      this.routeService.selectStep(step)
    } else {
      this.routeService.selectStep(null)
    }
  }

  isInactive(step) {
    return this.selectedStep !== null && this.selectedStep !== step
  }

  async applyCoupon(coupon) {
    await this.userService.addBalance(coupon, this.viajesService.currentTrip.getValue().id)
      .then(() => {
        this.analytics.logEvent(`exchanged_coupon_${coupon}`, {})
        this.toastService.presentToast('El cupón se ha aplicado correctamente', 'info')

        this.createPaymentIntent()
      })
      .catch(error => {
        let couponError
        if (error.message === "Request failed with status code 404") {
          couponError = [this.translate.instant("payments.coupon_not_found")]
        } else if (error.response.data.message.includes("Coupon is not available for user")) {
          couponError = [this.translate.instant("payments.coupon_other_user")]
        } else if (error.response.data.message.includes("has already used coupon")) {
          couponError = [this.translate.instant("payments.coupon_already_used")]
        } else {
          couponError = [this.translate.instant("payments.coupon_generic_error")]
        }
        this.toastService.presentToast(couponError, 'info')
      })
  }

  async createPaymentIntent(coupon?: string) {
    this.paymentIntent = await this.viajesService.createPaymentIntent(this.activo.id, { coupon, balance: true })

    this.card = this.paymentIntent.receipt.paymentables.find(payment => payment.payment_type === "stripe")?.amount
    this.balance = this.paymentIntent.receipt.paymentables.find(payment => payment.payment_type === "balance")?.amount

    this.changeDetector.detectChanges()
  }

  async pedirViaje() {
    this.analytics.logEvent(
      `abrir_${this.activo.operador}`,
      {
        familia: this.activo.familia.name,
        operador: this.activo.operador.name,
      }
    )

    this.analytics.logEvent(
      'pedir_viaje',
      {
        familia: this.activo.familia.name,
        operador: this.activo.operador.name,
        origen: JSON.stringify({lat: this.activo.origin.lat, lng: this.activo.origin.lng}),
        destino: JSON.stringify({lat: this.activo.destination.lat, lng: this.activo.destination.lng}),
      }
    )

    if (this.activo.operador.name === 'taxi033') {
      this.requestingPayment = true
      // await this.createPaymentIntent()
      const stripeObject = this.paymentIntent.receipt.paymentables.find(payment => payment.payment_type === "stripe")

      const payment_id = stripeObject ? stripeObject.stripe_client_secret.split("_secret_")[0] : this.paymentIntent.receipt.paymentables[0].id

      this.analytics.logEvent(
        'purchase',
        {
          currency: 'EUR',
          transaction_id: payment_id,
          value: this.activo.precio,
          affiliation: 'TSP',
          items: [{
            item_id: this.paymentIntent.id
          }]
        }
      )

      this.changeDetector.detectChanges()

      const card = this.cards[this.selectedCard]

      if (!card) {
        this.requestingPayment = false
        this.changeDetector.detectChanges()
        this.toastService.presentToast('Necesitas un método de pago', 'error', {
          buttons: [
            {
              text: 'OK',
              role: 'cancel'
            }
          ]
        })
      } else {
        try {
          if (stripeObject) {
            if (this.cards[this.selectedCard].brand === 'Apple') {
              await this.payWithApple(stripeObject)
            } else if (this.cards[this.selectedCard].brand === 'Google') {
              await this.payWithGoogle(stripeObject)
            } else {
              await this.payWithCard(stripeObject)
            }
          }
        } catch (error) {
          console.warn("error", error)
        }

        await this.viajesService.verifyPayment(this.paymentIntent.id, this.paymentIntent.activo_id, payment_id)

        this.requestingPayment = false
        this.paid = true
        this.changeDetector.detectChanges()

        this.analytics.logEvent(
          'pedir_taxi_taxi033',
          {
            precio: this.formatPrice(this.activo.precio),
            origen: this.originLocation,
            destino: this.destinationLocation
          }
        )
      }
    } else {
      this.openLink()
    }
  }

  openLink() {
    this.requested = true
    if (this.activo.operador.name === 'cooltra' || this.activo.familia.name === 'transporte_publico') {
      window.open(this.activo.deeplink)
    } else {
      launchApp(this.activo.operador.name, this.platform.platforms())
    }
  }

  openCurrentTripModal() {
    this.modalsService.presentModal('modalPayment')
  }

  login() {
    this.router.navigate(['/login'])
  }
}
