import { BuscadorComponent } from './buscador/buscador.component';
import { ButtonComponent } from './button/button.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GoogleInputComponent } from './google-input/google-input.component';
import { InputComponent } from '@components/input/input.component';
import { InputFileComponent } from './input-file/input-file.component';
import { IonicModule } from '@ionic/angular';
import { MathPipe } from '@app/pipes/math/math.pipe';
import { NgModule } from '@angular/core';
import { ResumenComponent } from './resumen/resumen.component';
import { RouterModule } from '@angular/router';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TranslateModule } from '@ngx-translate/core';
import { ResumenTaxiComponent } from './resumen/resumen-taxi/resumen-taxi.component';
import { PaymentComponent } from './payment/payment.component';
import { AddPaymentMethodComponent } from './payment/add-payment-method/add-payment-method.component';
import { ListPaymentMethodsComponent } from './payment/list-payment-methods/list-payment-methods.component';
import { TripStatusComponent } from './trip-status/trip-status.component';
import { AddPaymentMethodModalComponent } from './payment/add-payment-method-modal/add-payment-method-modal.component';
import { DriverInfoComponent } from './trip-status/driver-info/driver-info.component';
import { ResumenInfoComponent } from './resumen/resumen-info/resumen-info.component';
import { ResumenTransportePublicoComponent } from './resumen/resumen-transporte-publico/resumen-transporte-publico.component';
import { InfoActivoComponent } from './info-activo/info-activo.component';
import { ActivoComponent } from './activo/activo.component';
import { ModalInformComponent } from './modal-inform/modal-inform.component';
import { GoogleComponent } from './socialLogin/google/google.component';
import { AppleComponent } from './socialLogin/apple/apple.component';
import { SelectLocationModalComponent } from './select-location-modal/select-location-modal.component';
import { StoreUpdatesModalComponent } from './store-updates-modal/store-updates-modal.component';
import { ComparadorComponent } from './comparador/comparador.component';
import { SortActivosByPipe } from '@app/pipes/sortActivosBy/sort-activos-by.pipe';
import { ResumenRentComponent } from './resumen-rent/resumen-rent.component';
import { MyWaiisComponent } from './my-waiis/my-waiis.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { CarpoolingResultsComponent } from './carpooling-results/carpooling-results.component';
import { RoutesComponent } from './my-waiis/routes/routes.component';
import { CalendarComponent } from './my-waiis/calendar/calendar.component';
import { NgCalendarModule } from 'ionic6-calendar';
import { LocationPermissionsModal2Component } from './location-permissions-modal2/location-permissions-modal2.component';
import { LocationPermissionsModalComponent } from './location-permissions-modal/location-permissions-modal.component';

@NgModule({
  declarations: [
    BuscadorComponent,
    ButtonComponent,
    GoogleInputComponent,
    InputComponent,
    InputFileComponent,
    MathPipe,
    ResumenComponent,
    ResumenTaxiComponent,
    SidemenuComponent,
    PaymentComponent,
    AddPaymentMethodComponent,
    ListPaymentMethodsComponent,
    TripStatusComponent,
    AddPaymentMethodModalComponent,
    DriverInfoComponent,
    ResumenInfoComponent,
    ResumenTransportePublicoComponent,
    InfoActivoComponent,
    ActivoComponent,
    ModalInformComponent,
    GoogleComponent,
    AppleComponent,
    SelectLocationModalComponent,
    StoreUpdatesModalComponent,
    ComparadorComponent,
    SortActivosByPipe,
    ResumenRentComponent,
    MyWaiisComponent,
    InputSelectComponent,
    CarpoolingResultsComponent,
    RoutesComponent,
    CalendarComponent,
    LocationPermissionsModal2Component,
    LocationPermissionsModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    NgCalendarModule
  ],
  exports: [
    ButtonComponent,
    GoogleInputComponent,
    InputComponent,
    InputFileComponent,
    MathPipe,
    ResumenComponent,
    ResumenTaxiComponent,
    SidemenuComponent,
    BuscadorComponent,
    PaymentComponent,
    AddPaymentMethodComponent,
    ListPaymentMethodsComponent,
    TripStatusComponent,
    AddPaymentMethodModalComponent,
    DriverInfoComponent,
    ResumenInfoComponent,
    ResumenTransportePublicoComponent,
    InfoActivoComponent,
    ActivoComponent,
    ModalInformComponent,
    GoogleComponent,
    AppleComponent,
    SelectLocationModalComponent,
    StoreUpdatesModalComponent,
    ComparadorComponent,
    ResumenRentComponent,
    MyWaiisComponent,
    InputSelectComponent,
    CarpoolingResultsComponent,
    RoutesComponent,
    CalendarComponent,
    LocationPermissionsModal2Component,
    LocationPermissionsModalComponent
  ],
})
export class ComponentsModule { }
