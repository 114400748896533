import { Injectable } from '@angular/core';
import Ubicacion from '@app/models/Ubicacion';
import { AxiosService } from '@services/axios/axios.service';
import { BehaviorSubject } from 'rxjs';
import * as dayjs from 'dayjs';
import { ToastService } from '@services/toast/toast-service';
import { TranslateService } from '@services/translate/translate.service';
import { GeolocationService } from '@services/geolocation/geolocation.service';
import { Geolocation } from '@capacitor/geolocation';
import { LocationPermissionsService } from '@services/modals/locationPermissions/location-permissions.service';

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

class Routine {
  id: string
  name: string
  origin: {}
  destination: {}
  from_date: string
  to_date?: string
  type: 'driver' | 'rider' | 'both'
  selected_days: {
    departure_time: string
    arrival_time: string
    day: string
    type: 'driver' | 'rider' | 'both'
    route_type: 'destination' | 'origin'
    available_seats: number
    vehicle_id: string
    price: number
  }[]
};

class Route {
  arrival_time: string
  available_seats: number
  created_at: string
  deleted_at: string
  departure_time: string
  destination_id: string
  id: string
  origin_id: string
  pooling_routine_day_id: string
  pooling_routine_id: string
  start_date: string
  type: string
  updated_at: string
  user_id: string
}

@Injectable({
  providedIn: 'root'
})
export class RoutinesService {
  routineConfig = new BehaviorSubject<any>(null)
  origen = new BehaviorSubject<Ubicacion | null>(null)
  destino = new BehaviorSubject<Ubicacion | null>(null)

  routes = new BehaviorSubject<Route[]>([])
  routines = new BehaviorSubject<Routine[]>([])

  constructor(
    private axios: AxiosService,
    private toast: ToastService,
    private translate: TranslateService,
    private geolocation: GeolocationService,
    private locationPermissionsService: LocationPermissionsService
  ) { }

  setUbicacion(inputType: 'origen' | 'destino', ubicacion: Ubicacion) {
    this[inputType].next(ubicacion);
  }

  setRoutineConfig(config) {
    let configObj: Routine = new Routine()

    configObj.name = config.name

    configObj.origin = {
      lat: this.origen.value.coords.lat,
      lng: this.origen.value.coords.lng,
      locality: this.origen.value.locality,
      types: this.origen.value.types,
      long_address: this.origen.value.nombre_largo,
    }

    configObj.destination = {
      lat: this.destino.value.coords.lat,
      lng: this.destino.value.coords.lng,
      locality: this.destino.value.locality,
      types: this.destino.value.types,
      long_address: this.destino.value.nombre_largo,
    }

    configObj.selected_days = []

    Object.keys(config.days.ida).forEach(item => {
      const day = config.days.ida[item]
      configObj.selected_days.push({
        departure_time: day.exitTime,
        arrival_time: day.arrivalTime,
        day: weekdays[item],
        type: day.type,
        route_type: 'origin',
        available_seats: day.type === 'driver' ? day.capacity : null,
        vehicle_id: day.type === 'driver' ? day.vehicle?.id : null,
        price: day.type === 'driver' ? day.price : null
      })
    })

    Object.keys(config.days.vuelta).forEach(item => {
      const day = config.days.vuelta[item]
      configObj.selected_days.push({
        departure_time: day.exitTime,
        arrival_time: day.arrivalTime,
        day: weekdays[item],
        type: day.type,
        route_type: 'destination',
        available_seats: day.type === 'driver' ? day.capacity : null,
        vehicle_id: day.type === 'driver' ? day.vehicle?.id : null,
        price: day.type === 'driver' ? day.price : null
      })
    })

    configObj.type = 'driver'

    this.routineConfig.next(configObj)
  }

  async publishRoutine(startDate, endDate) {
    this.routineConfig.next({
      ...this.routineConfig.value,
      from_date: startDate.split('T')[0],
      to_date: endDate ? endDate.split('T')[0] : dayjs().add(1, 'year').format('YYYY-MM-DD')
    });

    Geolocation.checkPermissions().then(result => {
      if (result.location !== "granted") {
        this.locationPermissionsService.openModal()
      }
    })

    return (await this.axios.getInstance())
      .post('/pooling/routines', this.routineConfig.value)
  }

  async getRoutes() {
    return (await this.axios.getInstance())
      .get('/pooling/routes?append=price')
      .then(response => {
        if (response.data.data.length > 0) {
          this.geolocation.checkPermissions()
        }
        this.routes.next(response.data.data)
      })
  }

  async getRoutines() {
    return (await this.axios.getInstance())
      .get('/pooling/routines')
      .then(response => {
        this.routines.next(response.data.data)
      })
  }

  async getRoutine(id) {
    return (await this.axios.getInstance())
      .get(`/pooling/routines/${id}`)
      .then(response => {
        return response.data.data
      })
  }

  async getRecommendedPrice(fuelType, distance) {
    return (await this.axios.getInstance())
      .get(`/fuel-types/${fuelType}/recommended-price?distance=${distance}`)
      .then(response => {
        console.warn(response)
        return response.data.recommended_price
      })
  }

  async deleteRoute(id) {
    return (await this.axios.getInstance())
      .delete(`/pooling/routes/${id}`)
      .then(() => {
        const routes = this.routes.value.filter(route => route.id !== id)
        this.routes.next(routes)
        this.toast.presentToast(this.translate.instant('my_waiis.routes.delete_success'), 'info')
      })
      .catch(error => {
        if (error.response.status === 500) {
          this.toast.presentToast(this.translate.instant('errors.routes_delete_generic_error'), 'error')
        } else {
          this.toast.presentToast(this.translate.instant(`errors.${error.response.data.error_slug}`), 'error')
        }
        console.warn(error)
      })
  }

  async deleteRoutine(id, date) {
    const routine = this.routines.value.find(routine => routine.id === id)

    return (await this.axios.getInstance())
      .put(`/pooling/routines/${id}`, {...routine, to_date: date})
      .then((response) => {
        const routes = this.routes.value.filter(route => route.pooling_routine_id !== id || dayjs(route.start_date).isBefore(dayjs(date)))
        this.routes.next(routes)

        const routines = this.routines.value.map(routine => {
          if (routine.id === id) {
            return response.data
          } else {
            return routine
          }
        })
        this.routines.next(routines)

        this.toast.presentToast(this.translate.instant('my_waiis.routes.delete_routine_success'), 'info')
      })
      .catch((_) => {
        this.toast.presentToast(this.translate.instant('errors.routines_delete_generic_error'), 'error')
      })
  }
}
